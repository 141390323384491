import { ref, computed } from "vue";
import { ColorConfig, useGlobalConfig } from "vuestic-ui";

export const THEME_NAMES = {
  LIGHT: "LIGHT",
  ORIGINAL: "ORIGINAL",
  DARK: "DARK",
  SEMI_DARK: "SEMI_DARK",
};

export const COLOR_THEMES = [
  {
    name: THEME_NAMES.LIGHT,
    colors: {
      primary: "#101288",
      secondary: "#767c88",
      background: "#f6f7f6",
      success: "#31b9bf",
      info: "#2c82e0",
      danger: "#e42222",
      warning: "#ffd43a",
      white: "#ffffff",
      dark: "#262824",
      gray: "#e8eaee",
    },
    components: {
      VaNavbar: {
        color: "white",
        textColor: "primary",
      },
      VaSidebar: {
        color: "white",
        textColor: "primary",
      },
      VaSidebarItem: {
        color: "gray",
        textColor: "primary",
      },
      VaCard: {
        color: "white",
      },
      VaCardContent: {
        textColor: "primary",
      },
      VaCardTitle: {
        textColor: "primary",
      },
    },
  },
  {
    name: THEME_NAMES.DARK,
    colors: {
      primary: "#6F92DA",
      secondary: "#A8AEBA",
      background: "#2f302d",
      success: "#3d9209",
      info: "#2c82e0",
      danger: "#e42222",
      warning: "#ffd43a",
      white: "#262824",
      dark: "#ffffff",
      gray: "#f6f7f6",
    },
    components: {
      VaNavbar: {
        color: "white",
        textColor: "dark",
      },
      VaSidebar: {
        color: "white",
        textColor: "dark",
      },
      VaCard: {
        color: "white",
      },
      VaCardContent: {
        textColor: "dark",
      },
      VaCardTitle: {
        textColor: "dark",
      },
    },
  },
];

export function useTheme() {
  const themeNameRef = ref(THEME_NAMES.LIGHT);
  const { mergeGlobalConfig } = useGlobalConfig();

  const setTheme = (themeName: keyof typeof THEME_NAMES) => {
    themeNameRef.value = themeName;
    const theme = COLOR_THEMES.find((theme) => theme.name === themeName);

    if (!theme) {
      throw new Error("Theme not found");
    }

    mergeGlobalConfig({
      colors: { variables: theme.colors },
    });
  };

  const theme = computed(() =>
    COLOR_THEMES.find((theme) => theme.name === themeNameRef.value)
  );
  return { setTheme, themeName: themeNameRef, theme };
}
