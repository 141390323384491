import type { GlobalConfig } from "vuestic-ui";
import { COLOR_THEMES } from "./themes";

export default {
  colors: {
    presets: {
      light: {
        ...COLOR_THEMES[0].colors,
      },
    },
  },
  components: COLOR_THEMES[0].components,
};
